<template>
    <div >
        <v-dialog
       
      v-model="showDismissibleAlert"
      max-width="290"
    >
      <v-card :dark="$store.getters.getColorPalette().isDark">
        <v-card-title class="headline">{{$store.getters.getTextMap().info}}
</v-card-title>

        <v-card-text>
         {{info}}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          

          <v-btn
          :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="showDismissibleAlert = false"
          >
          {{$store.getters.getTextMap().ok}}

          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
        
        <div class="py-0">
          
          <v-form
        
    ref="parameterForm"
    v-model="valid" >
      <v-card :color="$store.getters.getColorPalette(). backgroundColorCode" :dark="$store.getters.getColorPalette().isDark">
        <v-card-title class="headline">
          {{$store.getters.getTextMap().add_device_parameter}}
        </v-card-title>
        <v-card-text>
            <v-container fluid>
                <v-row>
                    <v-col>
                      <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
            <v-text-field
          :dark="$store.getters.getColorPalette().isDark"
           :color="$store.getters.getColorPalette().inputtextColor"
            v-if="!loading"
      v-model="form.name"
      :counter="32"
      :rules="nameRules"
      :label="$store.getters.getTextMap().name"
      required
    ></v-text-field>
                    </v-col>
                    <v-col v-if="form.command!=1">
                      <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
    <v-select
  :dark="$store.getters.getColorPalette().isDark"
   :color="$store.getters.getColorPalette().inputtextColor"
    v-if="!loading"
          v-model="form.byte_format"
          :items="byteFormat"
          item-text="label"
          item-value="value"
          :label="$store.getters.getTextMap().byte_format"
         :rules="selectRules"
         required
          
          clearable
          :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
                    </v-col>
 <v-col v-if="form.command!=1">
   <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                        <v-text-field
 :dark="$store.getters.getColorPalette().isDark"
                      :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
                        v-model="form.unit"
                        :counter="5"
                        
                        :label="$store.getters.getTextMap().unit"
                        required>

                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    
                   
                    
                    <v-col v-if="form.command!=1">
                      <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                        <v-text-field
                       :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
                        v-model="form.correction_factor"
                        :counter="12"
                        :rules="floatRules"
                        :label="$store.getters.getTextMap().correction_factor"
                        required>

                        </v-text-field>
                    </v-col>

                     <v-col>
                       <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                        <v-select
                      :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
          v-model="form.latency"
          :items="latency"
          item-text="label"
          item-value="value"
          :label="$store.getters.getTextMap().idle_latency"
         :rules="selectRules"
         required
          
          clearable
     :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
                    </v-col>
                    <v-col v-if="form.command!=1">
                      <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                        <v-text-field
                        :dark="$store.getters.getColorPalette().isDark"
                       :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
                        v-model="form.entropy"
                        :counter="6"
                        :rules="floatRules"
                        :label="$store.getters.getTextMap().entropy"
                        required>

                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                   <v-col>
                     <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                        <v-select
                       :dark="$store.getters.getColorPalette().isDark"
                       :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
          v-model="form.command"
          :items="commands"
          item-text="label"
          item-value="value"
          :label="$store.getters.getTextMap().command"
         :rules="selectRules"
         required
          
          clearable
     :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
                    </v-col>
                    <v-col>
                      <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                        <v-text-field
                       :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
      v-model="form.address"
      :counter="5"
      :rules="numberRules"
      :label="$store.getters.getTextMap().address"
      required
    ></v-text-field>
                    </v-col>
                    <v-col v-if="form.command!=1">
                      <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                        <v-text-field
                       :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
      v-model="form.offset"
      :counter="2"
      :rules="numberRules"
      :label="$store.getters.getTextMap().offset"
      required
    ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                  <v-col v-if="form.command!=1">
                    <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                        <v-select
                       :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
          v-model="form.processing"
          :items="processing"
          item-text="label"
          item-value="value"
          :label="$store.getters.getTextMap().data_processing"
         :rules="selectRules"
         required
          
          clearable
     :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
                    </v-col>
                  <v-col>
                    <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                        <v-select
                        :dark="$store.getters.getColorPalette().isDark"
                         :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
          v-model="form.data_type"
          :items="dataTypes"
          item-text="label"
          item-value="value"
          :label="$store.getters.getTextMap().data_type"
         :rules="selectRules"
         required
          
          clearable
          :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
                    </v-col>
                  <v-col v-if="form.command!=1">
                    <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                        <v-select
                       :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
          v-model="form.register_size"
          :items="registerSize"
          item-text="label"
          item-value="value"
          :label="$store.getters.getTextMap().register_size"
         :rules="selectRules"
         required
          
          clearable
         :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
                    </v-col>
                    <v-col v-if="form.command!=1">
                      <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                        <v-select
                       :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
          v-model="form.val_type"
          :items="valTypes"
          item-text="label"
          item-value="value"
          :label="$store.getters.getTextMap().value_type"
         :rules="selectRules"
         required
          
          clearable
     :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
                    </v-col>
                </v-row>
    </v-container>
        </v-card-text>
        <v-card-actions>
          <v-checkbox
         :dark="$store.getters.getColorPalette().isDark"
           :color="$store.getters.getColorPalette().inputtextColor"
          v-if="!loading"
      v-model="form.enable_entropy"
      :label="$store.getters.getTextMap().enable_entropy"
    ></v-checkbox>
          <v-checkbox
         :dark="$store.getters.getColorPalette().isDark"
           :color="$store.getters.getColorPalette().inputtextColor"
          v-if="!loading"
      v-model="form.remove_decimal"
      :label="$store.getters.getTextMap().remove_decimal"
    ></v-checkbox>
          <v-checkbox
         :dark="$store.getters.getColorPalette().isDark"
           :color="$store.getters.getColorPalette().inputtextColor"
          v-if="!loading"
      v-model="form.engineerAccessOnly"
      :label="$store.getters.getTextMap().restrict_user_access"
    ></v-checkbox>
          <v-checkbox
         :dark="$store.getters.getColorPalette().isDark"
           :color="$store.getters.getColorPalette().inputtextColor"
          v-if="!loading"
      v-model="form.aliasing"
      :label="$store.getters.getTextMap().aliasing"
    ></v-checkbox>
          <v-checkbox
        :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().inputtextColor"
          v-if="!loading"
      v-model="form.rem_zero"
      :label="$store.getters.getTextMap().no_zero_read"
    ></v-checkbox>
          <v-checkbox
        :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().inputtextColor"
          v-if="!loading"
      v-model="form.rem_negative_entropy"
      :label="$store.getters.getTextMap().remove_negative_entropy"
    ></v-checkbox>
          <v-checkbox
        :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().inputtextColor"
          v-if="!loading"
      v-model="form.calculate_consumption"
      :label="$store.getters.getTextMap().calculate_consumption"
    ></v-checkbox>
          <v-checkbox
        :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().inputtextColor"
          v-if="!loading"
      v-model="form.disabled"
      :label="$store.getters.getTextMap().disabled"
    ></v-checkbox>
          <v-checkbox
        :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().inputtextColor"
          v-if="!loading"
      v-model="form.entropy_limiter"
      :label="$store.getters.getTextMap().limit_entropy"
    ></v-checkbox>
    <v-text-field
      :dark="$store.getters.getColorPalette().isDark"
      :color="$store.getters.getColorPalette().inputtextColor"
      v-if="!loading && form.entropy_limiter"
      v-model="form.entropy_lim_val"
      :counter="5"
      :rules="numberRules"
      :label="$store.getters.getTextMap().limiter_value"
      required
    ></v-text-field>
    <v-checkbox
        :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().inputtextColor"
          v-if="!loading"
      v-model="form.entropy_threshold"
      :label="$store.getters.getTextMap().entropy_threshold"
    ></v-checkbox>
    <v-text-field
      :dark="$store.getters.getColorPalette().isDark"
      :color="$store.getters.getColorPalette().inputtextColor"
      v-if="!loading && form.entropy_threshold"
      v-model="form.entropy_threshold_val"
      :counter="5"
      :rules="[rules.required, rules.unum, rules.noSpace]"
      :label="$store.getters.getTextMap().threshold_value"
      required
    ></v-text-field>
          <v-spacer></v-spacer>
          <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
    
          <v-btn
          v-if="!loading"
           :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="onSubmit"
          >
          {{$store.getters.getTextMap().create}}

          </v-btn>
          <v-btn
           :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="onReset"
          >
          {{$store.getters.getTextMap().cancel}}

          </v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import FormRules from '@/utillities/FormRules'
export default {
    name:'CreateParameter',
    props:['device'],
     data(){
        return {
            dialog:false,
            valid:false,
            loading:false,
            info:'',
            showDismissibleAlert:false,
            // byteFormat:['bigEndian','littleEndian',"midLittle",'midBig'],
            byteFormat:[
              {label:'bigEndian',value:'bigEndian'},
              {label:'littleEndian',value:'littleEndian'},
              {label:'midLittle (Little Endian Byte Swapped)',value:'midLittle'},
              {label:'midBig (Big Endian Byte Swapped)',value:'midBig'},
            ],
            latency:[
                {label:'5 minutes',value:300},
                {label:'15 minutes',value:900},
                {label:'Half hourly',value:1800},
                {label:'Hourly',value:3600},
                {label:'Quarter Day',value:21600},
                {label:'Half Day',value:43200},
                {label:'Daily',value:86400}                
            ],
            commands:[
                {label:'Read Input Register',value:'4'},
                {label:'Read Holding Register',value:'3'},
                {label:'Read Coil',value:'1'}
            ],
            processing:[
              {label:'Average',value:'mean'},
                {label:'Difference',value:'difference'},
                {label:'Negative Difference',value:'negative_difference'},
            ],
            registerSize:[
              {label:'short(16 bit)',value:'short'},
              {label:'long(32 bit)',value:'long'},
              {label:'long(64 bit)',value:'longlong'},
            ],
            valTypes:[
              {label:'Unsigned Integer',value:'uint'},
              {label:'Signed Integer',value:'int'},
              {label:'float',value:'float'}
            ],
            rules:FormRules.rules,
            nameRules: [
        v => !!v || 'Name is required',
        v => /\S+/.test(v) || 'Name is required',
        // v => (v && v.length <= 32 && v.length > 0) || 'Name must be less than 33 characters',
      ],
      unitRules:[
          v => !!v || 'Unit is required',
        v => /\S+/.test(v) || 'Unit is required',
        v => (v && v.length <= 5) || 'Unit length is invalid'
      ],
      numberRules:[
          v => !!v || 'Required',
          v => /[0-9]+$/.test(v) || 'It should be a number',
          v => (v && v>0 && v%1==0)|| 'It should be a valid number',
          v => (v && v.length <= 5 && v.length > 0) || 'Name must be less than 5 digits',
      ],
      floatRules:[
          v => !!v || 'Required',
          v => (v && !isNaN(Number(v))) || 'It should be a number',
          v => (v && v>0 )|| 'It should be more than zero',
          v => (v && v.length <= 12 && v.length > 0) || 'It must be less than 12 digits',
      ],
        msgRules: [
        v => !!v || 'Description is required',
        v => (v && v.length <= 35 && v.length > 0) || 'Description must be less than 35 characters',
      ],
      selectRules:[
        v=> (v!=null) || 'Required'
      ],
      form:{
                byte_format:null,
                correction_factor:null,
                register_size:'short',
                name:null,
                entropy:null,
                latency:3600,
                unit:null,
                command:null,
                address:null,
                offset:null,
                processing:null,
                engineerAccessOnly:false,
                aliasing:false,
                val_type:'uint',
                rem_zero:false
                
            }
        }
    },
    computed:{
      dataTypes(){
        let op=[]
        if(this.$store.state.dataTypes){
          for(let i of this.$store.state.dataTypes){
            op.push({
              label:i.type,
              value:i.id
            })
          }
        }
        return op
      }
    },
    methods:{
        onSubmit(){
            this.$refs.parameterForm.validate()
            if(this.valid){
              this.loading=true
            //evt.preventDefault()
            //this.form.id=(new Date).getTime().toString()
            let d=Object.assign({}, this.form)
            d.mgate_id=this.device.mgate_id
            d.port=this.device.port
            d.unit_id=this.device.unit_id
            d.device_id=this.device.device_id
            if(d.command==1){
              d.entropy=1
              d.val_type='uint'
              d.register_size='short'
              d.byte_format='bigEndian'
              d.offset=1
              d.correction_factor=1
            }
            console.log(d)
            axios.post(this.$store.state.api+'addParameter',d,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      if(response.data.status==='success'){
        //this.$store.commit('addParameter',d)
        this.$store.dispatch('refreshParameters')
        //console.log("Parameter added "+d)
        this.onReset()
            //this.dialog=false
            //this.$emit('close')
    }else{
      //console.log(response.data.msg)
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      this.loading=false
    }})
    .catch(err=>{console.log(err)});
                //this.$store.dispatch('addDevice', d)
            
            }
        },
        onReset(){
            //evt.preventDefault()
            /*this.form={
                id:null,
                msg:null,
                name:null
            }*/
            this.$refs.parameterForm.reset()
            this.dialog=false
            this.loading=false
            this.$emit('close')
        }

    }

}
</script>
<style scoped>
/* .v-application .headline{
  color:white;
}
.v-sheet.v-card {
   background-color: #263238;
} */
</style>


